<div class="trip-selector-container"  [ngClass]="{'closing' : closing}">
    <div class="half-top">&nbsp;</div>
    <div class="half-bottom">

        <app-banner-section [value]="'Select.your.trip'"></app-banner-section>
        <div class="trip-selector-list" *ngIf="accreditionList">
            <div *ngFor="let acc of accreditionList">
                <button class="btn trip-selector-button" (click)="selectAccreditation(acc)">
                    <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
                    <span>{{acc.name}}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="zone-cancel">
        <div>
            <button class="btn trip-selector-button" (click)="closeSelector()">{{'cancel' | translate}}</button>
        </div>
    </div>
</div>