import { Injectable } from '@angular/core';

class Notification {
  constructor(public text: string, public type: string, public reason = '') {}
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  TYPES = {
    default: 'primary',
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'danger',
  };
  private _isDisplayed = false;
  private _notification: Notification = {
    text: '',
    type: this.TYPES.error,
    reason: '',
  };

  showNotification(notification: Notification): void {
    this._notification = notification;
    this._isDisplayed = true;

    setTimeout(() => {
      this._isDisplayed = false;
    }, 2500);
  }

  default(message: string): void {
    this.showNotification(new Notification(message, this.TYPES.default));
  }

  success(message: string): void {
    this.showNotification(new Notification(message, this.TYPES.success));
  }

  error(message?: string, reason: string = ''): void {
    let _message = message || 'An.error.occured';
    this.showNotification(new Notification(_message, this.TYPES.error, reason));
  }

  info(message: string): void {
    this.showNotification(new Notification(message, this.TYPES.info));
  }

  warning(message: string): void {
    this.showNotification(new Notification(message, this.TYPES.warning));
  }

  get isDisplayed(): boolean {
    return this._isDisplayed;
  }

  get notification(): Notification {
    return this._notification;
  }
}
