import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
import { AuthenticationService } from '../services/authentication.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';

@Injectable({
  providedIn: 'root',
})
export class LocalisationLanguageService {

  private _usedLanguages = [
    { code: 'fr-FR', locale: 'fr', label: 'Français', flag: 'assets/icons/flag_fr.svg' },
    { code: 'en-US', locale: 'en', label: 'English', flag: 'assets/icons/flag_en.svg' },
    { code: 'de-DE', locale: 'de', label: 'Deutch', flag: 'assets/icons/flag_de.svg' },
    { code: 'es-ES', locale: 'es', label: 'Español', flag: 'assets/icons/flag_es.svg' },
    { code: 'it', locale: 'it', label: 'Italiano', flag: 'assets/icons/flag_it.svg' },
    { code: 'nl-NL', locale: 'nl', label: 'Nederlands', flag: 'assets/icons/flag_nl.svg' },
  ]

  corseOrganisationIds = ['172', '431', '142'];

  constructor(
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService,
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService
  ) { }

  public getDefaultLanguageByLocale(locale: string): string {
    let filteredLanguage = this._usedLanguages.find(l => l.locale == locale);
    if (filteredLanguage) {
      return filteredLanguage.code;
    }
    return 'en-US';
  }

  public async initLanguages() {

    const usedLangage = this._usedLanguages.map(l => l.locale);

    let currentUser;
    let userLanguage = '';
    if (this.authenticationService.isUserAuthenticated()) {
      currentUser = await this.authenticationService.getCurrentUser();
    }
    if (currentUser && currentUser.defaultLanguage) {
      userLanguage = currentUser.defaultLanguage.split('-')[0];
    }
    this.translateService.addLangs(usedLangage);
    this.translateService.setDefaultLang('en');

    let browserLang = this.translateService.getBrowserLang();
    let lang = this.localStorageService.get('lang');
    if (!lang && usedLangage.indexOf(userLanguage) !== -1) {
      lang = userLanguage;
    }
    if (!lang && usedLangage.indexOf(browserLang) !== -1) {
      lang = browserLang;
    }
    if (!lang) {
      lang = 'en';
    }
    this.translateService.use(lang);
    this.localStorageService.set('lang', lang);
    this.sessionStorage.set('lang', lang);

    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeNl, 'nl');
  }

  getDefaultLocale() {
    let lang = this.localStorageService.get('lang');
    return lang ? lang : 'en-US';
  }

  getUsedLanguages(organizationId?: string) {
    if (organizationId && this.corseOrganisationIds.indexOf(organizationId) != -1) {
      return this._usedLanguages.map(l => {
        if (l.locale == 'fr') {
          l.label = 'Corse';
          l.flag = 'assets/icons/flag_corse.png';
        }
        return l;
      })
    } else {
      return this._usedLanguages;
    }
  }

}
