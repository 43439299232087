import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CreateBookingService {

    constructor(private router: Router) {

    }
    private activeSubscription: any;
    public activeSubscriptionChanged$ = new BehaviorSubject<any>(null);

    private modalVisible = false;
    modalStatusChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private bookingAvailable = false;
    bookingAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public setActiveSubscription(subscription) {

        this.activeSubscription = subscription;
        this.activeSubscriptionChanged$.next(this.activeSubscription);

        this.bookingAvailable = subscription && (subscription.suspended == false);
        this.bookingAvailable$.next(this.bookingAvailable);
    }

    public createBooking() {

        const isLPV2 = this.activeSubscription.subscriptionType == 'LPV2';
        if(!isLPV2){ // Gestion LPV1
            this.router.navigate(['create-booking']);
            return;
        }

        let singleAcc = this.activeSubscription?.accreditations?.length == 1 ? this.activeSubscription?.accreditations[0] : null;
        if (singleAcc && singleAcc.boatId && singleAcc.trip && singleAcc.trip.multiDayBooking) {
            this.router.navigate(['create-long-booking', singleAcc.boatId, 'trip', singleAcc.trip.id, 'acc', singleAcc.id]);
        } else {
            // console.log(this.activeSubscription);
            if (this.activeSubscription?.organisationId == "60" || this.activeSubscription?.organisationId == "740") {
                this.showTripSelector()
            } else {
                this.router.navigate(['create-booking']);
            }
        }
    }

    private showTripSelector() {
        if (this.modalVisible) return;
        this.modalStatusChanged$.next(true);
        this.modalVisible = true;
    }

    public hideTripSelector(selectedAccreditation: any = null) {
        if (!this.modalVisible) return;
        this.modalStatusChanged$.next(false);
        this.modalVisible = false

        if (selectedAccreditation) {
            this.createBookingWithAccreditationSelected(selectedAccreditation);
            // this.router.navigate(['create-long-booking', selectedAccreditation.boatId, 'trip', selectedAccreditation.trip.id, 'acc', selectedAccreditation.id]);
        }
    }

    createBookingWithAccreditationSelected(singleAcc) {
        if (singleAcc && singleAcc.boatId && singleAcc.trip && singleAcc.trip.multiDayBooking) {
            this.router.navigate(['create-long-booking', singleAcc.boatId, 'trip', singleAcc.trip.id, 'acc', singleAcc.id]);
        } else {
            this.router.navigate(['create-booking']);
        }
    }

    notifyBookingAvailable() {
        this.bookingAvailable$.next(this.bookingAvailable);
    }



}
