import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChatApiService } from './api/chat-api.service';
import { AmityService } from './services/amity/Amity.service';
import { AuthenticationService } from './services/authentication.service';
import { LocalisationLanguageService } from './services/localisation-language.service';
import { UpdateService } from './services/update.service';
import { LoaderService } from './shared/components/loader/loader.service';
import { NotificationService } from './shared/components/notification/notification.service';
import { CreateBookingService } from './shared/components/trip-selector-panel/CreateBooking.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('tripSelector') public tripSelector: ElementRef;
  tripSelectorModal: NgbModalRef;

  title = 'nc-liberty-app';

  translations: string[];
  updateInformation: string;
  updateAvailable: boolean;
  version: string;
  domainName: string;

  organisation: any;
  boatClub: any;
  currentUser: any;

  constructor(
    private localisationLanguageService: LocalisationLanguageService,
    private sw: UpdateService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private http: HttpClient,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private amityService: AmityService,
    private chatApiService: ChatApiService,
    private createBookingService: CreateBookingService,
    private modalService: NgbModal
  ) {
    this.domainName = document.location.host.replace(/\./g, '-').replace(/:/g, '_');
  }

  async ngOnInit() {

    try {
      this.currentUser = await this.authenticationService.getCurrentUser(false);
      this.organisation = this.currentUser.ORGANISATION;
      this.boatClub = this.currentUser.BOAT_CLUB;
    } catch(e){
      console.error(e);
    }

    this.translations = this.translate.instant('pwa.updateNewVersionInformation');

    this.sw.updateInformation.subscribe(info => {
      this.notificationService.info(info);
      this.updateAvailable = true;
      this.updateInformation = info;
    });
    // check the service worker for updates
    this.sw.checkForUpdates();

    try {
      let res = await this.http.get('./ngsw.json').toPromise();
      this.version = res['appData'].version;
      this.sw.version = this.version;
    } catch (e) {
      //console.log(e);
    }

    this.localisationLanguageService.initLanguages();

    this.initChatService();
    this.initAddBookingBehavior()

  }

  reload() {
    document.location.reload();
  }

  async checkUpdateForced() {
    this.loaderService.showLoader();

    try {
      let res = await this.http.get('./ngsw.json').toPromise();
      let version = res['appData'].version;
      if (version != this.version) {
        this.forceUpdateNotif(version);
        this.sw.version = this.version;
      }
    } catch (e) {
      //console.log(e);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  forceUpdateNotif(newVersion) {
    this.translations = this.translate.instant(['pwa.updateNewVersionInformation']);
    let info = this.translations['pwa.updateNewVersionInformation'] + ` (${newVersion})`;
    this.updateAvailable = true;
    this.updateInformation = info;
  }

  initChatService() {

    // Subscribe Login Logoff
    this.authenticationService.userAuthenticatedChanged.subscribe(async (loggedIn) => {
      // console.log(`[appComponent.initChatService] loggedIn=${loggedIn}`)
      if (loggedIn) {
        let chatConfig = await this.chatApiService.getChatConfig();
        // console.log(chatConfig);
        await this.amityService.login(chatConfig);
      } else {
        this.amityService.logoff();
      }
    })

    this.authenticationService.notifyLoggedInStatus();

  }

  initAddBookingBehavior() {
    
    this.createBookingService.modalStatusChanged$.subscribe((display: boolean) => {
      if (display) {
        this.tripSelectorModal = this.modalService.open(this.tripSelector, { windowClass: 'modal-full', animation: false });
      }else{
        if(this.tripSelectorModal){
          this.tripSelectorModal.close();
        }
      }
    })
  }

}
