import { SwUpdate } from '@angular/service-worker';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    translations;
    updateInformation: EventEmitter<string> = new EventEmitter();
    updateSubscription;
    private _version: string;

    public set version(v: string) {
        this._version = v;
    }

    public get version(): string {
        return this._version;
    }

    constructor(
        public updates: SwUpdate,
        private translate: TranslateService,
    ) {

    }

    public checkForUpdates(): void {
        console.log(`check for update`);
        this.updateSubscription = this.updates.available.subscribe(event => {
            console.log(`[UpdateService] update available`);
            this.promptUser(event)
        });

        if (this.updates.isEnabled) {
            console.log('[UpdateService] update enabled');
            // Required to enable updates on Windows and ios.
            this.updates.activateUpdate();
            const delay = 1000 * 60 * 5; // 60 secondes * 5 => 5 Min.
            setInterval(() => {
                console.log('check for updates: start');
                this.updates.checkForUpdate().then((r: any) => {

                    console.log('check for updates: done', r);
                });
            }, delay)
            

        } else {
            console.log('[UpdateService] update not enabled');
        }
    }

    public promptUser(event): void {

        console.log('[UpdateService.promptUser] current version is', event.current);
        console.log('[UpdateService.promptUser] available version is', event.available);

        this.updates.activateUpdate()
        this.translations = this.translate.instant('pwa.updateNewVersionInformation');
        this.updateInformation.emit(this.translations['pwa.updateNewVersionInformation'] + ` (${event.available.appData.version})`)
    }

}