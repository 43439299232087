import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, tap } from 'rxjs/operators';
import { UpdateService } from '../services/update.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private updateService : UpdateService
    ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isAuthenticated = this.authenticationService.isUserAuthenticated();
    if (isAuthenticated) {
      let appVersion = this.updateService?.version || '' ;

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          'x-nauticoncept-club-app-version' :  appVersion 
        },
      });
    }

    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        // if (ev instanceof HttpResponse) {
        //   console.log('processing response', ev);
        // }
      }),
      catchError(response => {
              if (response instanceof HttpErrorResponse) {
                //console.log('Processing http error', response);
                if(response.status==401 && response.error=='ERR_INVALID_SESSION' ){
                  this.authenticationService.logout();
                }
                if(response.status==403 && response.error=='Forbidden' ){
                  this.authenticationService.logout();
                }
                if(response.status==403 && response.error?.reasonCode=='ERROR_NO_TENANT_ROLE' ){
                  this.authenticationService.logout();
                }
                return throwError(response);
              }
      })
    )
  }
}
