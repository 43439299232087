import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IChatConfig } from '../services/amity/IChatConfig';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ChatApiService {

    constructor(private http: HttpClient) { }

    async getChatConfig(): Promise<IChatConfig> {
        let url = `${environment.baseUrl}/chats/config`;
        return this.http.get<IChatConfig>(url).toPromise();
    }
}