import { version } from '../../package.json';

export const environment = {
  appVersion: version,
  production: true,
  baseUrl: 'https://apidev.nauticoncept.com',
  baseMediaUrl: 'https://apidev.nauticoncept.com',
  socialLpUrl: 'https://trips.libertypass.club',
  AmityApiKey: "b0eeb959398ea4621f33de14560b16888400d8e5ba343d25"
};
