import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateBookingService } from './CreateBooking.service';

@Component({
  selector: 'app-trip-selector-panel',
  templateUrl: './trip-selector-panel.component.html',
  styleUrls: ['./trip-selector-panel.component.scss']
})
export class TripSelectorPanelComponent implements OnInit {

  accreditionList: any[];
  
  closing = false;

  constructor(private createBookingService: CreateBookingService) { }

  ngOnInit(): void {
    this.createBookingService.activeSubscriptionChanged$.subscribe( (subscription) => {
      this.accreditionList = subscription?.accreditations;
    });
  }

  closeSelector(){
    this.closing = true;
    this.createBookingService.hideTripSelector();
  }

  selectAccreditation(acc){
    this.closing = true;
    this.createBookingService.hideTripSelector(acc);
    
  }

}
