import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationApiService } from '../api/authentication-api.service';
import { NotificationService } from '../shared/components/notification/notification.service';
import { LoaderService } from '../shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';

const TOKEN_NAME = 'clubAccessToken';
const USER_NAME = 'clubUser';
const apiBaseURL = `${environment.baseMediaUrl}`;
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    _currentUser: any;

    userAuthenticatedChanged: EventEmitter<boolean> = new EventEmitter();

    private profileSubscriberList: any[] = [];

    constructor(
        private router: Router,
        private authenticationApi: AuthenticationApiService,
        private notificationService: NotificationService,
        private loaderService: LoaderService
    ) { }

    public isUserAuthenticated(): boolean {
        if (this.getAccessToken()) {
            return true;
        }
        return false;
    }

    public async isUserProfileComplete(): Promise<boolean> {
        return true;
        // if (await this.getProfileStatus()) {
        //     return true;
        // }
        // return false;
    }

    public async login(email: string, password: string): Promise<any> {
        try {
            const accessToken = await this.authenticationApi.login(email, password);
            localStorage.setItem(TOKEN_NAME, JSON.stringify(accessToken));
            await this._fetchAndSetCurrentUser();
            this.router.navigate(['/']);
            this.userAuthenticatedChanged.emit(true);
        } catch (error) {
            console.error('authenticationService.login:', error);

            let errorMessage;
            switch (error.status) {
                case 401:
                    errorMessage = 'error.login.http.401';
                    break;
                default:
                    errorMessage = 'error.login.http.default';
                    break;
            }
            this.notificationService.error(errorMessage);
            this.logout();
        }
    }

    public logout() {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(USER_NAME);
        this.router.navigate(['/welcome']);
        this.userAuthenticatedChanged.emit(false);
    }

    public getAccessToken() {
        const accessToken = localStorage.getItem(TOKEN_NAME);
        if (!accessToken) return false;
        let token = JSON.parse(accessToken);
        return token.token;
    }

    public async getProfileStatus() {
        const currentUser = await this.getCurrentUser();
        if (!currentUser.fullIdentity.streetAddress || currentUser.fullIdentity.streetAddress.trim() == '') return false;
        if (!currentUser.fullIdentity.zipCode || currentUser.fullIdentity.zipCode.trim() == '') return false;
        if (!currentUser.fullIdentity.city || currentUser.fullIdentity.city.trim() == '') return false;
        if (!currentUser.signatureId) return false;
        if (!currentUser.identityAttachmentId) return false;
        return true;
    }

    public async getCurrentOrganisation(forced: boolean = false) {
        const currentUser = await this.getCurrentUser(forced);
        return currentUser ? currentUser.ORGANISATION : null;
    }

    public async getCurrentBoatClub(forced: boolean = false) {
        const currentUser = await this.getCurrentUser(forced);
        return currentUser ? currentUser.BOAT_CLUB : null;
    }

    public async getCurrentUser(forced: boolean = false) {
        if (forced) {
            await this._fetchAndSetCurrentUser();
        }
        if(this._currentUser) return this._currentUser;
        
        const currentUser = JSON.parse(localStorage.getItem(USER_NAME));
        if (!currentUser) {
            localStorage.removeItem(TOKEN_NAME);
            // location.reload();
            return null;
        }

        return currentUser;
    }

    async _fetchAndSetCurrentUser(): Promise<any> {
        const currentUser = await this.authenticationApi.fetchCurrentUser();
        currentUser.fullName = `${currentUser.fullIdentity.firstName || ''} ${currentUser.fullIdentity.lastName || ''}`;
        this._currentUser = currentUser;
        localStorage.setItem(USER_NAME, JSON.stringify(currentUser));
    }

    public async getCurrentUserWithAttachments(): Promise<any> {
        const currentUser = await this.authenticationApi.fetchCurrentUserWithAttachments();
        currentUser.fullName = `${currentUser.fullIdentity.firstName || ''} ${currentUser.fullIdentity.lastName || ''}`;
        this._currentUser = currentUser;
        localStorage.setItem(USER_NAME, JSON.stringify(currentUser));
        this.notifyProfileSubscribers();
        return currentUser;
    }

    public async setUser(accessToken) {
        this._setAccessToken({ token: accessToken });
        this.loaderService.showLoader();
        await this._fetchAndSetCurrentUser();
        this.loaderService.hideLoader();
    }

    private _setAccessToken(accessToken): void {
        localStorage.setItem(TOKEN_NAME,
            JSON.stringify(accessToken),
        );
    }

    subscribeProfileChanged(subscriber, fn) {
        this.profileSubscriberList.push({
            subscriber,
            fn
        });
    }

    unsubscribeProfileChanged(subscriber) {
        this.profileSubscriberList = this.profileSubscriberList.filter(s => s.subscriber !== subscriber);
    }

    notifyProfileSubscribers() {
        for (let subscriber of this.profileSubscriberList) {
            if (typeof (subscriber.fn) === 'function') {
                subscriber.fn();
            }
        }
    }

    async notifyLoggedInStatus() {
        let currentUser = await this.getCurrentUser(false);
        if (currentUser) {
            this.userAuthenticatedChanged.emit(true);
        }
    }

    async getFooterLogo() {
        let currentUser = await this.getCurrentUser();
        let boatClub = currentUser.BOAT_CLUB;
        let logo;

        if (boatClub && boatClub.appIco && boatClub.brandedFooterLogo) {
            logo = apiBaseURL + boatClub.appIco;
        } else {
            logo = `/assets/images/LibertyPass100.png`;
        }

        return logo;

    }

}
